import React from 'react';
import {Typography, Box} from '@mui/material';
import {styled} from '@mui/system';
import {CheckCircle} from '@mui/icons-material';
import {GradientCircularProgress} from '../../components/Icons/GradientCircularProgress';

const ContainerStyled = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '2%',
    backgroundColor: 'primary',
    color: 'onPrimary',
    alignItems: 'flex-start'
});

const PopupStyled = styled(Box)({
    padding: '2%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px'
});

const StepStyled = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start'
});

const SpinnerOrCheck = ({done}: {done: boolean}) => {
    if (done) {
        return (
            <Box position="relative" display="inline-flex" marginLeft="30px" marginRight="15px">
                <CheckCircle sx={{fontSize: '20px'}} />
            </Box>
        );
    } else {
        return <GradientCircularProgress gradientId="gradient-upload-files" />;
    }
};

/**
 * Calculate when the upload will be complete.
 *
 * @param uploadStart
 * @param completedRatio Number between 0 and 1
 * @return Estimated moment of completion. Current time if completedRatio is 0
 */
const uploadCompletesAt = (uploadStart: Date, completedRatio: number): Date => {
    if (completedRatio === 0) {
        return new Date();
    }
    const now = new Date();
    const elapsed_ms = now.getTime() - uploadStart.getTime();
    const estimated_total_ms = elapsed_ms / completedRatio;
    const remaining_ms = estimated_total_ms - elapsed_ms;
    return new Date(now.getTime() + remaining_ms);
};

/**
 * Format a date in browser's locale.
 *
 * Use this instead of toLocaleTimeString and friends in order to cache the
 * locale lookup.
 */
const remainingTimeFormatter = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
}).format;

interface SamplesProgressModal {
    fileUploadProgress: FileUploadProgress;
}

export const SamplesProgressModal: React.FC<SamplesProgressModal> = ({fileUploadProgress}: SamplesProgressModal) => {
    // 0/0 is NaN, so default to 0
    const completedRatio = fileUploadProgress.uploadedSize / fileUploadProgress.totalSize || 0;
    // Round to 0.1% precision. Ceil to show progress even if it's 0.1%. Cap at 99.9%.
    const percentageComplete = Math.min(Math.ceil(completedRatio * 1000) / 10, 99.9);
    const doneAt = uploadCompletesAt(fileUploadProgress.uploadStartedAt, completedRatio);
    const doneAtStr = completedRatio === 0 ? 'estimating…' : remainingTimeFormatter(doneAt);

    return (
        <ContainerStyled>
            <PopupStyled>
                <Typography variant="headline" size="large">
                    Processing Files
                </Typography>
                <Typography variant="body" size="large" sx={{borderBottom: '1px solid', borderColor: 'onPrimary', pb: 2}}>
                    Please keep this browser tab open and do not navigate away from this page until processing is complete.
                </Typography>
                <StepStyled>
                    <SpinnerOrCheck done={fileUploadProgress.createSamplesDone} />
                    <Typography>Create Samples</Typography>
                </StepStyled>
                <StepStyled>
                    <SpinnerOrCheck done={fileUploadProgress.uploadFilesDone} />
                    <Typography>
                        Upload Files ({percentageComplete.toFixed(1)}%)
                        <p>
                            {`${fileUploadProgress.uploadedFiles} of ${fileUploadProgress.totalFiles} files uploaded. Estimated completion time: ${doneAtStr}`}
                        </p>
                    </Typography>
                </StepStyled>
            </PopupStyled>
        </ContainerStyled>
    );
};
