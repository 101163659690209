import React from 'react';
import {CircularProgress} from '@mui/material';
import {Box} from '@mui/material';

interface GradientCircularProgressProps {
    gradientId: string;
}

/**
 * Spinning circle
 */
export const GradientCircularProgress: React.FC<GradientCircularProgressProps> = ({gradientId}) => (
    <Box position="relative" display="inline-flex" marginLeft="30px">
        <CircularProgress
            variant="indeterminate"
            size={20}
            thickness={4}
            sx={{
                '& circle': {
                    stroke: `url(#${gradientId})`
                },
                '@keyframes rotate': {
                    '0%': {
                        transform: 'rotate(0deg)'
                    },
                    '100%': {
                        transform: 'rotate(360deg)'
                    }
                },
                animation: 'rotate 2s linear infinite',
                mr: 2
            }}
        />
        <svg style={{position: 'absolute'}} width="0" height="0">
            <defs>
                <linearGradient id={gradientId} x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
                    {/* I dont think this first colour is actually visible at the moment */}
                    <stop stopColor="#7C57F4" />
                    <stop offset="1" stopColor="#F7218C" />
                </linearGradient>
            </defs>
        </svg>
    </Box>
);
