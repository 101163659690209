import React, {useEffect, useState} from 'react';
import {Typography, TextField, InputLabel, MenuItem, Select, Box, FormControl, Button} from '@mui/material';
import Popup from '../../../components/popup';
import {BeforeYouBegin} from '../../../components/modals/experiments/BeforeYouBegin';
import {useNavigate} from 'react-router-dom';
import {useTrovoConfig} from '../../../utils/config';

const Step1 = ({
    formData,
    setExperimentDetails
}: {
    formData: {experiment_details: ExperimentDetails};
    setExperimentDetails: (obj: ExperimentDetails) => void;
}) => {
    const navigate = useNavigate();
    const [isBeforeYouBegin, setBeforeYouBeginOpen] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {user} = useTrovoConfig();
    const [pageState, setPageState] = useState({
        name: {value: formData.experiment_details.name || '', error: ''},
        description: {value: formData.experiment_details.description || '', error: ''},
        organism: {value: formData.experiment_details.organism || '', error: ''},
        sequencing_type: {value: formData.experiment_details.sequencing_type || '', error: ''}
    });

    const isDemoAccount: boolean = user.cb_item_price_id?.includes('demo');
    const complete = Boolean(pageState.name.value && pageState.organism.value && pageState.sequencing_type.value);

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;
        setPageState({...pageState, [name]: {value: value, error: value === '' && 'Name cannot be empty.'}});
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        setExperimentDetails({
            name: pageState.name.value,
            description: pageState.description.value,
            organism: pageState.organism.value,
            sequencing_type: pageState.sequencing_type.value
        });
    };

    return (
        <Box component="form" sx={{'& label': {width: '75ch'}, display: 'flex', flexDirection: 'column', gap: '15px'}}>
            <TextField
                name="name"
                label="Name"
                fullWidth
                disabled={isDemoAccount}
                value={pageState.name.value}
                placeholder="Enter a name"
                onChange={handleInputChange}
                error={Boolean(pageState.name.error)}
                helperText={pageState.name.error}
            />

            <TextField
                name="description"
                label="Description (optional)"
                fullWidth
                value={pageState.description.value}
                disabled={isDemoAccount}
                placeholder="Enter a description"
                onChange={handleInputChange}
            />
            <FormControl>
                <InputLabel>Select Organism</InputLabel>
                <Select
                    MenuProps={{
                        PaperProps: {
                            sx: {bgcolor: 'surface-container-low'}
                        }
                    }}
                    name="organism"
                    label="Select Organism"
                    value={pageState.organism.value}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={isDemoAccount}
                    required>
                    <MenuItem value="Homo sapiens">Homo sapiens</MenuItem>
                    <MenuItem value="Felis catus">Felis catus</MenuItem>
                    <MenuItem value="Mus musculus">Mus musculus</MenuItem>
                    <MenuItem value="Sus scrofa">Sus scrofa</MenuItem>
                    <MenuItem value="Rattus norvegicus">Rattus norvegicus</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>Sequencing type</InputLabel>
                <Select
                    MenuProps={{
                        PaperProps: {
                            sx: {bgcolor: 'surface-container-low'}
                        }
                    }}
                    name="sequencing_type"
                    label="Sequencing type"
                    disabled={isDemoAccount}
                    value={pageState.sequencing_type.value}
                    onChange={handleInputChange}
                    fullWidth
                    required>
                    <MenuItem value="RNA Seq">RNA Seq</MenuItem>
                </Select>
                <Typography variant="body" size="small" sx={{color: 'on-surface-variant', textAlign: 'center', mb: 2}}>
                    We currently only support RNA Seq experiments, but we are actively working to expand our support for additional
                    experiment types.
                </Typography>
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!complete || isDemoAccount || isSubmitting}
                fullWidth>
                Save and Continue
            </Button>
            <Button variant="text" color="primary" onClick={() => navigate(`/`)} fullWidth disabled={isDemoAccount}>
                Save Progress and Exit
            </Button>
            <Popup
                isOpen={isBeforeYouBegin}
                onClose={() => setBeforeYouBeginOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <BeforeYouBegin onClose={() => setBeforeYouBeginOpen(false)} />
            </Popup>
        </Box>
    );
};

export default Step1;
