import React, {useEffect, useState, useRef} from 'react';
import {Alert, Box, Button, Snackbar, Typography} from '@mui/material';
import Popup from '../../../../components/popup';
import {SamplesReview} from './SamplesReview';
import VariablesTable from './VariablesTable';
import {useNavigate, useParams} from 'react-router-dom';
import UpdatedSamplesTable from './SamplesTable';
import {getAppConfig} from '../../../../utils/config';
import {useAuth0} from '@auth0/auth0-react';
import {ButtonRow} from '../../../../components/ButtonRow';
import AddNewSample from '../../../ManageSamples/AddNewSample';
import {trimKeysAndValuesInObject, sortObjectKeys} from '../../../../utils/helpers';
import {validateFile} from '../../../../utils/file-upload';

type FileData = {
    id: string;
    name: string;
    status?: string;
    lane_number?: number;
    read_number?: number;
};

type SampleData = {
    number_of_files?: any;
    name: string;
    other_categories: any;
    files?: Array<FileData>;
    id: string;
};

type SelectedSample = {
    value: string;
};

type SampleErrors = {
    /** UUID of samples */
    missingFiles: string[];
    /** UUID of samples */
    duplicateName: string[];
    /** UUID of samples */
    fileStatus: string[];
};

/**
 * Component that enables the user to review and edit the samples and their
 * variables. Also offers adding new samples, deleting existing ones, and
 * fixing failed uploads.
 */
const Step4 = ({experiment, setStep, setFormData}: any) => {
    const {id} = useParams();
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();
    const navigate = useNavigate();
    const [addingSample, setAddingSample] = useState(false);
    const [deleteCallback, setDeleteCallback] = useState<any>(null);
    const [samplesToReview, setSamplesToReview] = useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSamples, setSelectedSamples] = useState(new Set());
    const [errors, setErrors] = useState<SampleErrors>({missingFiles: [], duplicateName: [], fileStatus: []});
    const [samples, setSamples] = useState([]);
    const selectedSamplesArray = Array.from(selectedSamples).map(value => ({value: value as string}));
    const [isDuplicationStarted, setDuplicationStarted] = useState(false);

    // Derive what samples to display from samples and searchTerm.
    const lowersearch = searchTerm.toLowerCase();
    const displaySamples = !searchTerm ? samples : samples?.filter((sample: any) => sample?.name?.toLowerCase().includes(lowersearch));

    /**
     * Loops through samples' other_categories, collects keys and their unique values.
     *
     * @return New shallow copy object with the keys and their unique values
     */
    const parseCategories = (samples: {other_categories: Record<string, any>}[]): Record<string, any[]> => {
        const result: Record<string, Set<any>> = {};
        samples.forEach(sample => {
            const categories = sample.other_categories;
            for (const key in categories) {
                if (!result[key]) {
                    result[key] = new Set();
                }
                // TODO: Can the value be an object, i.e., are we passing by reference here?
                result[key].add(categories[key]);
            }
        });
        const finalResult: Record<string, any[]> = {};
        for (const key in result) {
            finalResult[key] = Array.from(result[key]);
        }
        return finalResult;
    };

    const globalValues = sortObjectKeys(parseCategories(samples));

    // Checks the samples and files for errors
    const checkSamples = async (samplesArr: any[], expectedFileCount: any) => {
        const errorsArr: SampleErrors = {
            missingFiles: [],
            duplicateName: [],
            fileStatus: []
        };
        const apiAccessToken = await getAccessTokenSilently();
        samplesArr.forEach((sample: any) => {
            sample.files.map(async (file: any) => {
                await validateFile(apiHost, apiAccessToken, id!, sample.id, file.id);
            });
        });
        const nameSet = new Set();
        samplesArr?.forEach((sample: any) => {
            const normalizedName = sample?.name?.trim().toLowerCase();
            if (nameSet?.has(normalizedName)) {
                errorsArr?.duplicateName?.push(sample.id);
            } else {
                nameSet.add(normalizedName);
            }
            if (expectedFileCount - sample?.files.length > 0) {
                errorsArr.missingFiles.push(sample.id);
            }
            if (sample.files.some((f: any) => f.status !== 'ready' && f.status !== 'unknown')) {
                errorsArr.fileStatus.push(sample.id);
            }
        });
        setErrors(errorsArr);
    };

    // Handlers for edits made with the Variables Table --->
    const handleVariableChange = async (newKey: string, oldKey: string) => {
        const editPromises = samples?.forEach((sample: any) => {
            if (sample.other_categories && oldKey in sample.other_categories) {
                const oldValue = sample.other_categories[oldKey];
                delete sample.other_categories[oldKey];
                sample.other_categories[newKey.trim()] = oldValue;
                return editSample(sample);
            }
        });
        try {
            await editPromises;
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating samples:', error);
        } finally {
            reFetchSamples();
        }
    };

    const handleValueChange = (newValue: string, key: string, index: number) => {
        const changedSamples: any[] = [];
        const oldValue = globalValues[key][index];
        samples.forEach((sample: any) => {
            if (sample.other_categories[key] === oldValue) {
                sample.other_categories[key] = newValue;
                changedSamples.push(sample);
            }
        });
        try {
            changedSamples.forEach((s: any) => {
                editSample(s);
            });
        } catch (err) {
            console.error(err);
        } finally {
            setSamplesToReview({changedSamples, oldValue, newValue, key});
            reFetchSamples();
        }
    };

    // Handlers for search/filter ---->
    const debounceSearchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (debounceSearchTimeoutRef.current) {
            clearTimeout(debounceSearchTimeoutRef.current);
        }
        debounceSearchTimeoutRef.current = setTimeout(() => {
            setSearchTerm(event.target.value);
            debounceSearchTimeoutRef.current = null;
        }, 300);
    };

    const getSelectedSamples = (data: SampleData[], selectedSamples: SelectedSample[]): SampleData[] => {
        const selectedIds = selectedSamples.map((s: SelectedSample) => s.value);
        return data.filter((sample: SampleData) => selectedIds.includes(sample.id));
    };

    const filteredData = getSelectedSamples(displaySamples || [], selectedSamplesArray || []);

    const handleSelectRow = (id: any) => {
        const newSelectedSamples = new Set(selectedSamples);

        if (id && selectedSamples.has(id)) {
            newSelectedSamples.delete(id);
        } else {
            newSelectedSamples.add(id);
        }

        setSelectedSamples(newSelectedSamples);
    };

    /**
     * Removes a sample from the selected samples.
     *
     * Uses setSelectedSamples.
     */
    const handleRemoveSample = (sampleId: string | undefined) => {
        if (typeof sampleId === 'string') {
            setSelectedSamples((prevSelectedSamples: Iterable<unknown> | ArrayLike<unknown>) => {
                const updatedSelectedSamples = new Set(Array.from(prevSelectedSamples));
                updatedSelectedSamples.delete(sampleId);

                return updatedSelectedSamples;
            });
        }
    };

    // Handlers for loading & updating the page ---->
    const reFetchSamples = async () => {
        const response = await fetch(`${apiHost}/experiment/${id}/sample`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            }
        });
        const result = await response.json();
        await checkSamples(result, experiment?.files_per_sample);
        setSamples(result);
    };

    const editSample = async (sample: any) => {
        try {
            // This does nothing since it returns a new object. Does it even
            // make sense here to begin with?
            trimKeysAndValuesInObject(sample.other_categories);
            console.log(sample.other_categories);
            Object.keys(sample).forEach(key => {
                if (key === 'flag' || key === 'archived') {
                    sample[key] = false;
                } else if (sample[key] === null) {
                    key === 'number_of_files' ? (sample[key] = 0) : (sample[key] = '');
                }
            });
            const response = await fetch(`${apiHost}/experiment/${id}/sample/${sample.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                },
                body: JSON.stringify(sample)
            });
            await response.json();
            await reFetchSamples();
        } catch (err) {
            console.error(err);
        }
    };

    // Do an initial fetch seperately from the openSnackbar one below. At the
    // start, that message is not open yet, so will not actually fetch samples
    // due to the if check there.
    useEffect(() => {
        reFetchSamples();
    }, []);

    // Opening of the snackbar (tends to) mean(s) that a file was added or some
    // other change. Fetching again to ensure we are in sync with bff. Only
    // doing it on open, not on close too.
    useEffect(() => {
        if (openSnackbar) {
            reFetchSamples();
        }
    }, [openSnackbar]);

    const handleSave = () => {
        setFormData((state: any) => ({...state, step_three_details: globalValues}));
        setStep(4);
    };

    useEffect(() => {
        if (!filteredData.length) {
            setDuplicationStarted(false);
        }
    }, [filteredData]);

    return (
        <>
            <Box sx={{mb: 4, py: 2, px: 3, background: 'white', borderRadius: 4}}>
                <VariablesTable
                    globalValues={globalValues}
                    data={displaySamples}
                    handleValueChange={handleValueChange}
                    handleVariableChange={handleVariableChange}
                />
            </Box>
            <Box sx={{my: 3, p: 3, background: 'white', borderRadius: 4}}>
                <Typography variant="headline" size="small">
                    Review your individual samples
                </Typography>
                <Typography mb={3} variant="body" size="medium">
                    Make changes to the values assigned to your individual samples, and review your sample pairings to make sure they are
                    accurate.
                </Typography>
                {addingSample && (
                    <Box py={1} sx={{borderBottom: '1px solid', borderColor: 'outline-variant'}}>
                        <AddNewSample
                            data={displaySamples}
                            exp={experiment}
                            setAdding={setAddingSample}
                            sampleSource={displaySamples[0] || [{}]}
                            reFetchSamples={reFetchSamples}
                        />
                    </Box>
                )}

                {isDuplicationStarted &&
                    filteredData.map((sample, index) => (
                        <AddNewSample
                            key={index}
                            data={displaySamples}
                            exp={experiment}
                            setAdding={setAddingSample}
                            isDuplicationStarted={isDuplicationStarted}
                            sampleSource={sample}
                            onRemoveSample={handleRemoveSample}
                            reFetchSamples={reFetchSamples}
                        />
                    ))}

                {!addingSample && !isDuplicationStarted && (
                    <ButtonRow
                        onSearchChange={handleSearchChange}
                        addClick={setAddingSample}
                        onDelete={deleteCallback}
                        areSelectedSamples={!!selectedSamplesArray.length}
                        setDuplicationStarted={setDuplicationStarted}
                    />
                )}

                <UpdatedSamplesTable
                    experiment={experiment}
                    samples={displaySamples}
                    errors={errors}
                    globalValues={globalValues}
                    fetchSamples={reFetchSamples}
                    setDeleteCallback={setDeleteCallback}
                    editSample={editSample}
                    setSuccess={setOpenSnackbar}
                    handleSelectRow={handleSelectRow}
                    selectedSamples={selectedSamples}
                    setSelectedSamples={setSelectedSamples}
                />
            </Box>
            <Button
                variant="contained"
                color="primary"
                style={{margin: '20px 0'}}
                fullWidth
                onClick={handleSave}
                disabled={Object.values(errors).some((arr: any) => arr.length > 0)}>
                Save and Continue
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate(`/`)} fullWidth>
                Save Progress and Exit
            </Button>
            <Popup isOpen={Boolean(samplesToReview)} onClose={() => setSamplesToReview(null)}>
                <SamplesReview samplesToReview={samplesToReview} onClose={() => setSamplesToReview(null)} />
            </Popup>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity="success">Changes were saved successfully!</Alert>
            </Snackbar>
        </>
    );
};

export default Step4;
