import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import SampleTemplate from '../SampleTemplate';
import Papa, {ParseResult as PapaParseResult} from 'papaparse';
import {useNavigate} from 'react-router-dom';
import SampleBulkUpload from './SampleBulkUpload';
import Step3HasSamples from './Step3HasSamples';
import {parseValidSamples, parseValidFiles, validateCSV} from './ParseCsv';
import {Error} from '../../../../components/Error';

const Step3Index = ({experiment, createSamples, samples, setStep}: any) => {
    const csvRef = React.useRef<HTMLInputElement>(null);
    const fastqRef = React.useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [showUploader, setShowUploader] = useState(false);
    const [csvError, setCsvError] = useState('');
    const [pageState, setPageState] = useState<{csv: {file: File; contents: any}; fastq_files: File[]}>({
        csv: {file: {} as File, contents: {}},
        fastq_files: []
    });

    const complete = Boolean(pageState.csv.file?.name && pageState.fastq_files.length > 0 && csvError === '');

    /**
     * Downloads a sample CSV template by creating a Blob and a temporary
     * `<a>`.
     */
    const handleDownloadClick = () => {
        const blob = new Blob([SampleTemplate], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'sampleTemplate.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    /**
     * Propagates the click event to the hidden file input element.
     */
    const handleSelectFile = (type: 'csv' | 'fastq') => {
        if (type === 'csv') {
            csvRef.current?.click();
        } else {
            fastqRef.current?.click();
        }
    };

    /**
     * Removes a file from the page state.
     */
    const handleRemoveFile = (type: 'csv' | 'fastq', fileName?: string) => {
        if (type === 'csv') {
            setPageState((state: any) => ({...state, csv: {file: {}, contents: {}}}));
            if (csvRef.current) {
                // Explicitly clear the file input, otherwise you get in a
                // state were the pageState is empty but the file input still
                // has a file selected. This becomes problematic if the user
                // tries to upload the same file again, because the onChange
                // won't trigger.
                csvRef.current.value = '';
            }
        } else {
            setPageState((state: any) => ({
                ...state,
                fastq_files: state.fastq_files.filter((file: any) => file.name !== fileName)
            }));
        }
    };

    /**
     * Parses the uploaded CSV file and adds the file and its parsed contents
     * to the page state.
     */
    const handleCSVUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setCsvError('');
        const filesList = event.target.files;
        if (!filesList) {
            setCsvError('No file selected');
            return;
        }
        const file = filesList.item(0);
        if (!file) {
            setCsvError('No file selected');
            return;
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: (result: PapaParseResult<Record<string, any>>) => {
                // TODO: Report errors to the user? Should this clear the upload form?
                // The really helpful papaparse documentation:
                // "Just because errors are generated does not necessarily mean
                // that parsing failed. The worst error you can get is probably
                // MissingQuotes."
                // So errors being present says nothing? Hiding from user for the time being.
                if (result.errors.length > 0) {
                    console.log('PapaParse error', result.errors);
                }

                const parsedCSV = {file: file, contents: result.data};

                const csvIsValid = validateCSV(parsedCSV);

                if (!csvIsValid) {
                    setCsvError('CSV file is not valid');
                    return;
                }

                setPageState((state: any) => ({...state, csv: {file: filesList.item(0), contents: result.data}}));
            }
        });
    };

    /**
     * Adds the selected files to the page state.
     */
    const handleFASTQUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const filesList = event.target.files;
        if (!filesList || filesList?.length === 0) return;
        let files: File[] = [];
        for (let i = 0; i < filesList.length; i++) {
            files.push(filesList[i]);
        }
        setPageState((state: any) => ({...state, fastq_files: [...state.fastq_files, ...files]}));
    };

    const handleSaveAndContinue = () => {
        const {csv, fastq_files} = pageState;
        const csvrows = csv?.contents || [];

        if (!csvrows.length) {
            console.error('CSV file empty');
            return;
        }

        const firstOfEachUniqueSample = parseValidSamples(csvrows);

        const sampleNameToValidFilenames = parseValidFiles(csvrows, fastq_files, experiment?.sequencing_read_type);

        createSamples({
            ...pageState,
            values_by_sample: firstOfEachUniqueSample,
            files_list: sampleNameToValidFilenames
        });
    };

    return (
        <Box>
            {experiment?.sample_count > 0 && samples?.length && !showUploader ? (
                <Step3HasSamples samples={samples} setStep={setStep} setShowUploader={setShowUploader} />
            ) : (
                <>
                    <SampleBulkUpload
                        pageState={pageState}
                        handleDownloadClick={handleDownloadClick}
                        handleSelectFile={handleSelectFile}
                        handleRemoveFile={handleRemoveFile}
                        handleCSVUpload={handleCSVUpload}
                        csvRef={csvRef}
                        handleFASTQUpload={handleFASTQUpload}
                        fastqRef={fastqRef}
                    />
                    {csvError && <Error message={'CSV error: ' + csvError} />}
                    <Button variant="contained" color="primary" onClick={handleSaveAndContinue} disabled={!complete} fullWidth>
                        Save and Continue
                    </Button>
                    <Button variant="text" color="primary" onClick={() => navigate(`/`)} fullWidth>
                        Save Progress and Exit
                    </Button>
                </>
            )}
        </Box>
    );
};

export default Step3Index;
