import React, {useEffect, useState} from 'react';
import {Box, Button, Typography, TextField, Grid} from '@mui/material';
import {ErrorRounded} from '@mui/icons-material';
import {useBff, useTrovoConfig} from '../../utils/config';
import {capitalize} from '../../utils/helpers';
import {IExperimentDetails} from '../../types/experiment';

interface EditExperimentProps {
    data: IExperimentDetails;
    onClose: () => void;
}

const EditExperiment: React.FC<EditExperimentProps> = ({data, onClose}) => {
    const [experimentName, setExperimentName] = useState(data?.name);
    const [experimentDescription, setExperimentDescription] = useState(data?.description);
    const [shouldSave, setShouldSave] = useState(false);
    const {user} = useTrovoConfig();
    const [backendData, backendError, isBackendLoading] = useBff(
        shouldSave ? 'PUT' : '',
        `experiment/${data.id}`,
        JSON.stringify({name: experimentName, description: experimentDescription})
    );

    useEffect(() => {
        console.log(backendData, backendError);
        if (backendData) {
            onClose();
        }
        if (backendError) {
            console.error('Error saving data:', backendError.error);
            setShouldSave(false);
        }
    }, [backendData, backendError]);

    const {
        organism,
        analyzed_molecule,
        rna_selection_method,
        platform_model,
        sequencing_adapter,
        sequencing_platform,
        sequencing_read_type,
        sequencing_sense,
        sequencing_type
    } = data;
    const fields = {
        organism,
        rna_selection_method,
        analyzed_molecule,
        platform_model,
        sequencing_adapter,
        sequencing_platform,
        sequencing_read_type,
        sequencing_sense,
        sequencing_type
    };

    return (
        <Box style={{padding: '1.5% 4%'}}>
            <Typography variant="headline" size="medium" mb={2} pb={1} sx={{borderBottom: '1px solid', borderColor: 'outline'}}>
                Edit Experiment
            </Typography>
            <Typography variant="title" size="large" pb={2}>
                Experiment Details
            </Typography>
            <Box mb={2} component="form">
                <Typography mb={1}>Experiment Name</Typography>
                <TextField
                    id="experimentName"
                    disabled={user.cb_item_price_id?.includes('demo') || data.id === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9'}
                    value={experimentName}
                    onChange={e => setExperimentName(e.target.value)}
                    fullWidth
                />
            </Box>
            <Typography mb={1}>Description (optional)</Typography>
            <TextField
                id="experimentDescription"
                value={experimentDescription}
                multiline
                disabled={user.cb_item_price_id?.includes('demo') || data.id === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9'}
                fullWidth
                maxRows={5}
                onChange={e => setExperimentDescription(e.target.value)}
            />

            <Box mt={2}>
                <Box display="flex" alignItems="center">
                    <Box
                        mb={1}
                        sx={{
                            bgcolor: 'tertiary-container',
                            padding: '2%',
                            borderRadius: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '15px'
                        }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ErrorRounded />
                        </Box>
                        <Typography variant="title" size="medium">
                            Note: The following summary selections are locked. Changes to the selections below are only available if you
                            make a duplicate of this Experiment. Once duplicated, your data will be re-processed with updated parameters.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} maxHeight={'317px'}>
                <Grid container spacing={2}>
                    {data &&
                        Object.entries(fields).map(([k, v]) => (
                            <Grid item sm={6} lg={6} xl={6} key={k}>
                                <TextField
                                    fullWidth
                                    label={k.split('_').map(capitalize).join(' ')}
                                    value={v !== '' ? v : 'N/A'}
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                        ))}
                </Grid>
            </Box>

            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="outlined" onClick={onClose} sx={{borderRadius: '50px', px: 3}}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShouldSave(true)}
                    sx={{marginLeft: '16px', borderRadius: '90px', px: 3}}
                    disabled={user.cb_item_price_id?.includes('demo') || data.id === '4015c7cc-1982-4bd4-ae1c-140a7aa6fba9'}>
                    Save & Update
                </Button>
            </Box>
        </Box>
    );
};

export default EditExperiment;
