import {Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Row from './SampleRow';
import {Cancel, WarningAmberOutlined} from '@mui/icons-material';

import {useAuth0} from '@auth0/auth0-react';
import {getAppConfig} from '../../../../utils/config';
import Popup from '../../../../components/popup';

/**
 * Utility function that compares name property. To be used in a sort()
 * function.
 *
 * @return -1, 1, 0
 * */
const nameSorter = (a: any, b: any) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

interface SamplesTableProps {
    setSuccess: any;
    samples: any;
    errors: {missingFiles: string[]; duplicateName: string[]; fileStatus: string[]};
    globalValues: any;
    fetchSamples: any;
    experiment: any;
    setDeleteCallback: any;
    editSample: any;
    handleSelectRow: (sample_id: string) => void;
    selectedSamples: any;
    setSelectedSamples: any;
}

const SamplesTable = ({
    setSuccess,
    samples,
    errors,
    globalValues,
    fetchSamples,
    experiment,
    setDeleteCallback,
    editSample,
    handleSelectRow,
    selectedSamples,
    setSelectedSamples
}: SamplesTableProps) => {
    const {apiHost} = getAppConfig();
    const {getAccessTokenSilently} = useAuth0();
    const [deletePopup, setDeletePopup] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedSamples = new Set(samples.map((sample: any) => sample.id));
            setSelectedSamples(newSelectedSamples);
        } else {
            setSelectedSamples(new Set());
        }
        setSelectAll(!selectAll);
    };

    const triggerDelete = () => {
        if (selectedSamples.size > 0) {
            setDeletePopup(true);
        }
    };

    const handleDelete = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const deletePromises = Array.from(selectedSamples).map(s => {
                return fetch(`${apiHost}/experiment/${experiment.id}/sample/${s}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                });
            });
            const responses = await Promise.all(deletePromises);
            setSelectedSamples(new Set());
            setDeletePopup(false);
            setSuccess(true);
            fetchSamples();
        } catch (error) {
            console.error('Error during deletion:', error);
        }
    };

    useEffect(() => {
        setDeleteCallback(() => triggerDelete);
    }, [selectedSamples]);

    const erroringIds: Set<string> = errors ? new Set(Object?.values(errors)?.flat()) : new Set();
    const sortedRows =
        samples?.toSorted((a: any, b: any) => {
            const bError = erroringIds?.has(b.id);
            const aError = erroringIds?.has(a.id);
            if (aError && !bError) return -1;
            if (!aError && bError) return 1;
            return nameSorter(a, b);
        }) || [];

    return (
        <Box>
            <TableContainer
                sx={{
                    border: '1px solid',
                    borderColor: 'outline-variant',
                    borderRadius: '5px',
                    mt: 3,
                    paddingBottom: '10px',
                    '& input': {
                        height: '23px',
                        fontSize: '15px',
                        padding: '4px'
                    }
                }}>
                <Table aria-label="collapsible table" sx={{'th, td': {border: 0}}}>
                    <TableHead sx={{th: {bgcolor: 'surface-container-high', height: '25px', py: 0}}}>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell padding="checkbox">
                                <Checkbox
                                    size="small"
                                    onChange={e => handleSelectAllRows(e)}
                                    checked={selectAll}
                                    indeterminate={selectedSamples.size > 0 && selectedSamples.size < samples.length}
                                    sx={{padding: 1}}
                                />
                            </TableCell>
                            <TableCell>Sample Name</TableCell>
                            {samples && samples?.length ? (
                                Object.keys(globalValues)?.map((k: any, i: number) => {
                                    return <TableCell key={i}>{k}</TableCell>;
                                })
                            ) : (
                                <TableCell></TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows?.length > 0 ? (
                            sortedRows?.map((row: any, i: number) => (
                                <React.Fragment key={row?.id}>
                                    <TableRow sx={{my: 1, borderTop: '1px solid', borderColor: '#e0dcea6e'}} />
                                    <Row
                                        globalValues={globalValues}
                                        exp={experiment}
                                        row={row}
                                        errors={errors}
                                        fetchSamples={fetchSamples}
                                        selected={selectedSamples.has(row?.id)}
                                        selectRow={handleSelectRow}
                                        editSample={editSample}
                                        setSuccess={setSuccess}
                                    />
                                </React.Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell sx={{textAlign: 'center'}}>No results to display</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {deletePopup && (
                <Popup isOpen={true} onClose={() => {}}>
                    <Box py={5} px="8%" sx={{display: 'flex', flexDirection: 'column', alignItems: 'left', cursor: 'pointer', gap: 3}}>
                        <WarningAmberOutlined sx={{fontSize: '60px', color: '#BA1A1A', my: 1, alignSelf: 'center'}} />
                        <Typography variant="title" size="large">
                            {' '}
                            Are you sure you want to delete these samples?{' '}
                        </Typography>
                        <Typography variant="body" size="medium">
                            This action cannot be undone and will permanently delete these samples from your experiment. Deleting these
                            files will not affect any analyses you've already run, but these samples cannot be included in future analyses.{' '}
                        </Typography>
                        <Typography variant="body" size="medium">
                            {' '}
                            Do you still want to delete the selected samples?
                        </Typography>
                        <Button variant="contained" onClick={handleDelete}>
                            {' '}
                            Delete ({selectedSamples.size}) sample{`${selectedSamples.size > 1 ? 's' : ''}`}{' '}
                        </Button>
                        <Button endIcon={<Cancel />} onClick={() => setDeletePopup(false)}>
                            Nevermind, cancel
                        </Button>
                    </Box>
                </Popup>
            )}
        </Box>
    );
};

export default SamplesTable;
