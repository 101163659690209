import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box, TableContainer, TextField, InputAdornment} from '@mui/material';
import {Search} from '@mui/icons-material';
import {capitalize} from '../../../../utils/helpers';

const SearchField = ({_searchTerm, setSearchTerm}: any) => {
    return (
        <TextField
            sx={{'& .MuiInputBase-input': {width: '200px', height: '35px', p: 0}, mb: 1}}
            variant="outlined"
            placeholder="Search..."
            onChange={e => setSearchTerm(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                )
            }}
        />
    );
};

function SelectComparisons({contrastOptions, formData, setFormData, setAbleToSave, setError}: any) {
    const [selected, setSelected] = useState<Set<number>>(new Set());
    const [searchTerm, setSearchTerm] = useState('');

    function formatContrastOptions(contrastOptions: any[]) {
        return contrastOptions?.map(option => {
            const [controlValue, experimentalValue] = option.split(' - ').reverse();
            return {
                controlValue,
                experimentalValue
            };
        });
    }

    const data = formatContrastOptions(contrastOptions)?.filter(
        row =>
            row?.controlValue?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row?.experimentalValue.toLowerCase().includes(searchTerm.toLowerCase())
    );

    /**
     * If row is in selection, remove it from selection. If row is not in
     * selection, add it to selection.
     */
    const handleSelect = (index: number) => {
        // Need to create a new set to trigger React re-render (it uses obj identity).
        const newSelected: Set<number> = new Set(selected);
        if (selected.has(index)) {
            newSelected.delete(index);
        } else {
            newSelected.add(index);
        }
        setSelected(newSelected);
    };

    const handleSelectAll = () => {
        if (selected.size < data.length) {
            setSelected(new Set(data.map((_, index) => index)));
            return;
        }
        setSelected(new Set());
    };

    // When selection changes, enable/disable save button and update form data.
    useEffect(() => {
        if (selected.size === 0) {
            setError('Please select at least one comparison.');
            setAbleToSave(false);
        } else {
            setError(null);
            setAbleToSave(true);
        }

        let values: any[] = [];
        contrastOptions.forEach((d: any, i: number) => selected.has(i) && values.push(d));
        setFormData({...formData, selected_contrast_options: values});
    }, [selected]);

    return (
        <Box p={3} mr={5} sx={{borderRadius: '12px', bgcolor: 'surface-container-lowest'}}>
            {/* Disabled for now. When a row is already selected, typing
                something in the search bar keeps the index, even though there
                is a different row there now.
            <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            */}
            <TableContainer sx={{border: '1px solid', borderColor: 'outline-variant', borderRadius: '6px'}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{bgcolor: 'surface-container-highest'}}>
                            <TableCell padding="checkbox">
                                <Checkbox checked={data.length > 0 && selected.size === data.length} onChange={handleSelectAll} />
                            </TableCell>
                            <TableCell>CONTROL VALUE</TableCell>
                            <TableCell> </TableCell>
                            <TableCell>EXPERIMENTAL VALUE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow
                                key={index}
                                onClick={() => handleSelect(index)}
                                hover
                                sx={{bgcolor: selected.has(index) ? 'surface-container' : 'surface-container-lowest'}}>
                                <TableCell padding="checkbox">
                                    <Checkbox checked={selected.has(index)} />
                                </TableCell>
                                <TableCell>{row.controlValue.split('_').map(capitalize).join(' and ')}</TableCell>
                                <TableCell>COMPARE TO</TableCell>
                                <TableCell>{row.experimentalValue.split('_').map(capitalize).join(' and ')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default SelectComparisons;
