interface UploadHooks {
    /**
     * Called every time a single file is uploaded successfully.
     */
    onSuccess?: (url: string, response: Response) => void;
    /**
     * Called every time a single file upload fails.
     */
    onError?: (url: string, error: Error) => void;
    /**
     * Called every time a single file upload is retried.
     */
    onRetry?: (url: string, attempt: number) => void;
}

interface UploadOptions {
    maxConcurrent?: number;
    maxRetries?: number;
    hooks?: UploadHooks;
}

/**
 * Contact BFF to get a presigned AWS URL to which the file can be uploaded.
 *
 * @param apiHost Something like `https://yourhost`
 * @param apiAccessToken Will be passed in the Authorization header, `Bearer YOURTOKEN`.
 *                 Generally this can be obtained with `await getAccessTokenSilently()`.
 * @return The url to which the file can be uploaded and the provided filename
 */
export const getPresignedUrl = async (
    apiHost: string,
    apiAccessToken: string,
    experimentId: string,
    sampleId: string,
    fileId: string,
    filename: string
): Promise<{url: string; filename: string}> => {
    return fetch(`${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/get-presigned-url`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiAccessToken}}`
        }
    })
        .then(response => {
            return response.json();
        })
        .then(data => {
            return {url: data.url, filename: filename};
        });
};

/**
 * Calls validate-file-upload for the file in the sample. Note that this is
 * an endpoint with side-effects. The file information is compared to what
 * is found in S3. The database is updated with the result of the
 * comparison.
 *
 * @param apiHost Something like `https://yourhost`
 * @param apiAccessToken Will be passed in the Authorization header, `Bearer YOURTOKEN`.
 *                 Generally this can be obtained with `await getAccessTokenSilently()`.
 * @return success property is true if file is validated
 */
export const validateFile = async (
    apiHost: string,
    apiAccessToken: string,
    experimentId: string,
    sampleId: string,
    fileId: string
): Promise<{success: boolean; message: string}> => {
    const response = await fetch(`${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/validate-file-upload`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiAccessToken}`
        }
    });
    const status = await response.json();
    return status;
};

/**
 * Uploads a list of files to a list of URLs. Uploads are done concurrently up
 * to a maximum. Uploads are retried up to a maximum.
 *
 * @param urls The URLs to upload the files to.
 * @param files The files to upload, must be the same length as `urls`.
 */
export const uploadFiles = async (urls: Readonly<string[]>, files: Readonly<File[]>, options: UploadOptions = {}) => {
    const {maxConcurrent = 3, maxRetries = 2, hooks = {}} = options;
    const queue = urls.map((url, index) => ({
        url,
        file: files[index]
    }));

    const activeUploads = new Set<Promise<Response>>();

    async function uploadWithRetry(url: string, file: File, attempts: number): Promise<Response> {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: file
            });
            if (!response.ok) {
                throw new Error(`Upload failed: ${response.status}`);
            }
            hooks.onSuccess?.(url, response);
            return response;
        } catch (error) {
            if (attempts < maxRetries) {
                hooks.onRetry?.(url, attempts + 1);
                return uploadWithRetry(url, file, attempts + 1);
            }
            hooks.onError?.(url, error as Error);
            throw error;
        }
    }

    while (queue.length > 0 || activeUploads.size > 0) {
        while (activeUploads.size < maxConcurrent && queue.length > 0) {
            const item = queue.shift()!;
            const uploadPromise = uploadWithRetry(item.url, item.file, 0).finally(() => activeUploads.delete(uploadPromise));
            activeUploads.add(uploadPromise);
        }

        // First promise to resolve or reject. Rest continues running still.
        await Promise.race(activeUploads);
    }
};

interface MultiPartStartResponse {
    uploadId: string;
    urls: string[];
}

/**
 * Upload a single file using multipart uploading. Due to the multiple parts
 * and our large files, this can be parallelized sufficiently, there is no need
 * to deal with multiple files at once.
 */
export const uploadFileMultiPart = async (
    apiHost: string,
    apiAccessToken: string,
    experimentId: string,
    sampleId: string,
    fileId: string,
    file: File,
    hooks: MultiPartUploadHooks = {}
): Promise<boolean> => {
    const partSize = 10 * 1024 * 1024; // 10 MB
    const partCount = Math.ceil(file.size / partSize);
    const startUrl = `${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/multipart-start`;
    const {uploadId, urls}: MultiPartStartResponse = await fetch(startUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiAccessToken}`
        },
        body: JSON.stringify({partCount})
    }).then(resp => resp.json());

    // Link URLs to parts
    const sliceUrls = urls.map((url, index) => ({
        url,
        // S3 part numbers are 1-indexed
        PartNumber: index + 1,
        file: file.slice(index * partSize, (index + 1) * partSize)
    }));

    const parts = await multiPartPool(sliceUrls, {hooks});
    parts.sort((a, b) => a.PartNumber - b.PartNumber);
    if (parts.length !== partCount) {
        // TODO Abort the multipart upload
    }

    // TODO If any of the above throw, abort the multipart upload

    // Complete the upload
    const completeUrl = `${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/multipart-complete`;
    const resp = await fetch(completeUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiAccessToken}`
        },
        body: JSON.stringify({uploadId, parts})
    });
    return resp.ok && resp.status === 200;
};

interface MultiPartChunk {
    url: string;
    PartNumber: number;
    file: Blob;
}

interface MultiPartChunkResult {
    PartNumber: number;
    ETag: string;
}

interface MultiPartUploadHooks {
    /**
     * Called every time a chunk is uploaded successfully.
     */
    onChunkSuccess?: (chunkPart: number, chunkSize: number) => void;
    /**
     * Called every time a chunk upload fails and is retried.
     */
    onChunkRetry?: (chunkPart: number, attempt: number) => void;
    /**
     * Called when giving up on retrying.
     */
    onChunkError?: (chunkPart: number, error: Error) => void;
}

interface MultiPartUploadOptions {
    maxConcurrent?: number;
    maxRetries?: number;
    hooks?: MultiPartUploadHooks;
}

/**
 * Throws if any part fails to upload.
 */
const multiPartPool = async (uploadParts: MultiPartChunk[], options: MultiPartUploadOptions = {}): Promise<MultiPartChunkResult[]> => {
    const {maxConcurrent = 3, maxRetries = 2, hooks = {}} = options;
    const queue = [...uploadParts];

    const activeUploads = new Set<Promise<MultiPartChunkResult>>();

    async function uploadWithRetry(part: MultiPartChunk, attempts: number): Promise<MultiPartChunkResult> {
        try {
            const response = await fetch(part.url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: part.file
            });
            if (!response.ok) {
                throw new Error(`Upload failed: ${response.status}`);
            }

            hooks.onChunkSuccess?.(part.PartNumber, part.file.size);
            const etag = response.headers.get('ETag');
            if (!etag) {
                throw new Error('No ETag header found in S3 response. Required for completion.');
            }
            return {
                PartNumber: part.PartNumber,
                ETag: etag
            };
        } catch (error) {
            if (attempts < maxRetries) {
                hooks.onChunkRetry?.(part.PartNumber, attempts + 1);
                return uploadWithRetry(part, attempts + 1);
            }
            hooks.onChunkError?.(part.PartNumber, error as Error);
            throw error;
        }
    }

    const results: MultiPartChunkResult[] = [];
    while (queue.length > 0 || activeUploads.size > 0) {
        while (activeUploads.size < maxConcurrent && queue.length > 0) {
            const part = queue.shift()!;
            const uploadPromise = uploadWithRetry(part, 0).finally(() => activeUploads.delete(uploadPromise));
            activeUploads.add(uploadPromise);
        }

        // First promise to resolve or reject. Rest continues running still.
        const result = await Promise.race(activeUploads);
        results.push(result);
    }

    return results;
};
