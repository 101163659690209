import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    ArrowRightAlt,
    Edit,
    ErrorOutline,
    CheckCircle,
    CheckCircleOutline,
    Replay,
    ErrorSharp
} from '@mui/icons-material';
import {
    TableRow,
    TableCell,
    IconButton,
    Checkbox,
    TextField,
    MenuItem,
    Select,
    Collapse,
    Table,
    TableHead,
    Button,
    TableBody,
    Typography,
    Box
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import EditMetaForm from '../../../ManageSamples/EditMetaForm';
import Popup from '../../../../components/popup';
import FileUploadBox from '../../../ManageSamples/FileUploadBox';
import {getAppConfig} from '../../../../utils/config';
import {useAuth0} from '@auth0/auth0-react';
import {GradientCircularProgress} from '../../../../components/Icons/GradientCircularProgress';
import {uploadFileMultiPart} from '../../../../utils/file-upload';

const SampleMetadataTable = ({sample, experiment}: {sample: any; experiment: any}) => {
    return (
        <Table size="small" sx={{mt: 1}}>
            <TableHead>
                <TableRow sx={{bgcolor: 'surface-container-high'}}>
                    <TableCell>Sample ID</TableCell>
                    <TableCell>Seq-end</TableCell>
                    <TableCell>Sense</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow sx={{bgcolor: 'surface-container-low'}}>
                    <TableCell>{'S-' + sample?.sample_num}</TableCell>
                    <TableCell>{experiment?.sequencing_read_type || sample?.files?.[0]?.strandedness_seq_end}</TableCell>
                    <TableCell>{experiment?.sequencing_sense || 'unstranded'}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

/**
 * List a sample's files and enable the user to upload new files.
 */
const SampleFilesTable = ({
    sample,
    experiment,
    setRetryUpload,
    setEditMeta,
    selectedFiles,
    setSelectedFiles,
    processAllFiles,
    uploading,
    finished
}: any) => {
    return (
        <Table size="small" sx={{mt: '3px'}}>
            <TableHead>
                <TableRow sx={{bgcolor: 'surface-container-high'}}>
                    <TableCell> </TableCell>
                    <TableCell>File ID</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell sx={{pl: 3}}>Status</TableCell>
                    <TableCell>Lane Number</TableCell>
                    <TableCell>Read Number</TableCell>
                    <TableCell> </TableCell>
                </TableRow>
            </TableHead>

            {/* --- File Display for Sample  ---> */}
            <TableBody sx={{bgcolor: 'surface-container-low'}}>
                {sample?.files?.map((fileEntry: any) => (
                    <TableRow key={fileEntry.id}>
                        <TableCell align="right">
                            {/* the download button below is disabled for now. uncomment to enable 
                         <CloudDownload sx={{ fontSize:'18px', color:'gray' }} /> */}
                        </TableCell>
                        <TableCell align="left">SM-{fileEntry.id.slice(0, 5)}</TableCell>
                        <TableCell>{fileEntry.name}</TableCell>
                        {/* ---- File upload Retry button & file upload popup ----> */}
                        <TableCell align="left">
                            {fileEntry.status === 'ready' ? (
                                <Typography>
                                    <CheckCircleOutline color="disabled" sx={{fontSize: '18px'}} /> ready
                                </Typography>
                            ) : (
                                <Button
                                    variant="text"
                                    sx={{mx: 0, px: 1}}
                                    startIcon={<ErrorOutline color="error" sx={{fontSize: '18px', mx: 0}} />}
                                    endIcon={<Replay sx={{mx: 0}} />}
                                    color="primary"
                                    onClick={() => setRetryUpload(fileEntry)}>
                                    {fileEntry.status}
                                </Button>
                            )}
                        </TableCell>
                        {/* ---- Files with no Metadata Display add Metadata warning and button Here ----> */}
                        <TableCell align="center">
                            {'00' + fileEntry?.lane_number || (
                                <Button
                                    variant="text"
                                    startIcon={<ErrorOutline />}
                                    endIcon={<ArrowRightAlt />}
                                    color="error"
                                    onClick={e => setEditMeta(fileEntry)}>
                                    <Typography variant="label" size="medium - prominent">
                                        Add Metadata
                                    </Typography>
                                </Button>
                            )}
                        </TableCell>
                        <TableCell align="center">
                            {fileEntry?.read_number || (
                                <Button
                                    variant="text"
                                    startIcon={<ErrorOutline />}
                                    endIcon={<ArrowRightAlt />}
                                    color="error"
                                    onClick={e => setEditMeta(fileEntry)}>
                                    <Typography variant="label" size="medium - prominent">
                                        Add Metadata
                                    </Typography>
                                </Button>
                            )}
                        </TableCell>
                        <TableCell>
                            {' '}
                            <Edit sx={{fontSize: '18px', cursor: 'pointer'}} onClick={e => setEditMeta(fileEntry)} />{' '}
                        </TableCell>
                    </TableRow>
                ))}

                {/* --- File Upload Box Conditional Rendering For File Uploading ---> */}
                <TableRow sx={{py: 0}}>
                    <TableCell colSpan={8}>
                        <Box p={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                            {sample?.files?.length < experiment?.files_per_sample &&
                                (!uploading ? (
                                    <>
                                        <FileUploadBox
                                            maxFiles={experiment.files_per_sample - sample.files.length}
                                            selectedFiles={selectedFiles}
                                            onFilesSelected={setSelectedFiles}
                                        />
                                        <Button
                                            variant="contained"
                                            size="small"
                                            disabled={selectedFiles?.length + sample.files.length < experiment.files_per_sample}
                                            sx={{mt: 1, alignSelf: 'center', px: 2, py: 1}}
                                            onClick={processAllFiles}>
                                            Upload Files
                                        </Button>
                                    </>
                                ) : (
                                    <Box>
                                        {selectedFiles?.map((f: any) => (
                                            <Typography variant="body" size="medium">
                                                {f.name}{' '}
                                                {finished.includes(f.name) ? (
                                                    <CheckCircle />
                                                ) : (
                                                    <GradientCircularProgress gradientId="gradient-add-files" />
                                                )}{' '}
                                            </Typography>
                                        ))}
                                    </Box>
                                ))}
                            {sample?.files?.length == experiment?.files_per_sample && (
                                <Typography
                                    align="center"
                                    border={'1px dashed'}
                                    borderColor={'outline-variant'}
                                    p={1}
                                    color={'outline-variant'}>
                                    You've reached the maximum number of files allowed for this sample.
                                </Typography>
                            )}
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

/**
 * Row representing one sample. The `row` prop is the sample data.
 */
export default function Row(props: any) {
    const {row, setSuccess, globalValues, errors, exp, selected, selectRow, fetchSamples, editSample} = props;
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();

    const [retryUpload, setRetryUpload] = useState<any>(null);
    const [editMeta, setEditMeta] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState(false);
    const [finished, setFinished] = useState<string[]>([]);
    const [editState, setEditState] = useState<{[key: string]: string}>({});

    // Start open state with the correct value
    const errorInSample = Object.keys(errors).some((m: any) => errors[m].includes(row.id));
    const [open, setOpen] = useState(errorInSample);
    // TODO: Can I just get rid of this? If an error is fixed, dont we reFetchSamples? That is completely new samples so completely new rows.
    // Explicitly update open state when the errors change
    useEffect(() => {
        setOpen(errorInSample);
    }, [errors]);

    const errorMessages: Record<string, string> = {
        missingFiles: `This sample is missing files. Add (${exp?.files_per_sample - row?.files.length}) files to resolve this issue.`,
        duplicateName:
            'This sample has the same name as another sample in your experiment. Please give this sample a unique name or delete it.',
        fileStatus: 'There was an error uploading your file. Please try uploading it again.'
    };

    const handleChange = (identifier: string, newValue: string) => {
        setEditState({[identifier]: newValue});
    };

    const handleSaveChanges = (key: any) => {
        if (editState[key] !== row[key] && editState[key] !== row.other_categories[key]) {
            if (key === 'name') {
                row.name = editState.name;
            } else {
                row.other_categories[key] = editState[key];
            }
            editSample(row);
            setEditState({});
            setSuccess(true);
        }
    };

    // TODO: Only used in one spot, why is this not a function that can be
    // applied on an selectedFiles' item when we need it?
    const formattedFiles = selectedFiles.map((obj: File) => {
        const filename = obj.name;
        const filenameSplit = filename.split('_');
        const laneNumber = filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 3][3] : '1';
        const readNumber =
            filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 2][1] : filenameSplit.length === 2 ? filenameSplit[1][0] : '1';
        return {
            name: filename,
            original_size: obj.size,
            strandedness_seq_sense: exp.sequencing_sense || '',
            strandedness_seq_end: exp.sequencing_read_type && exp.sequencing_read_type.toLowerCase(),
            interleaved: false,
            status: 'unknown',
            lane_number: laneNumber,
            read_number: readNumber,
            sample_id: row.id
        };
    });

    /**
     * Contacts the bff to create a new RawFile entry in the database
     * associated with the current sample.
     *
     * The return type is the minimal we need when using this function. The
     * actual object will have other properties too.
     */
    const addFileToSample = async (fileObject: any): Promise<{id: string; name: string}> => {
        const response = await fetch(`${apiHost}/experiment/${exp.id}/sample/${row.id}/file/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            },
            body: JSON.stringify(fileObject)
        });
        return await response.json();
    };

    /**
     * Uploads all files in the selectedFiles array. If no previous file
     * existed, a new RawFile record is created in the database.
     */
    async function processAllFiles() {
        setUploading(true);
        let filesToUpload: {id: string; name: string; fileObj: File}[] = [];
        if (!!retryUpload) {
            // When an upload has failed, the user is asked to select the file
            // with the same name as the failed upload. Only this file is
            // uploaded. Hence selectedFiles[0]
            filesToUpload = [{id: retryUpload.id, name: retryUpload.name, fileObj: selectedFiles[0]}];
        } else {
            // User is adding files to a sample for the first time
            filesToUpload = await Promise.all(
                formattedFiles.map(async (obj: any) => {
                    const fileRecord = await addFileToSample(obj);
                    // Asserting it is a File by construction for the type checker
                    const fileObj = selectedFiles.find((f: any) => f.name === fileRecord.name) as File;
                    return {id: fileRecord.id, name: fileRecord.name, fileObj};
                })
            );
        }
        const apiAccessToken = await getAccessTokenSilently();
        for (const fileToUpload of filesToUpload) {
            // TODO: Just soft ignores error.
            await uploadFileMultiPart(apiHost, apiAccessToken, exp.id, row.id, fileToUpload.id, fileToUpload.fileObj)
                .then(_data => {
                    setFinished((state: any) => [fileToUpload.name, ...state]);
                })
                .catch(err => {
                    console.error('Error uploading file:', err);
                });
        }
        setRetryUpload(null);
        setSuccess(true);
        setUploading(false);
        fetchSamples();
    }

    return (
        <>
            {/* Error Messages Box for Row with Errors */}
            <TableRow>
                <TableCell colSpan={8}>
                    {Object.keys(errors).map(
                        (m: any) =>
                            errors[m].includes(row.id) && (
                                <Box
                                    key={m}
                                    sx={{
                                        px: 2,
                                        py: 1,
                                        my: '1px',
                                        borderRadius: '4px',
                                        bgcolor: 'error-container',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start'
                                    }}>
                                    <Typography display={'flex'} alignItems={'center'} gap={2}>
                                        <ErrorOutline fontSize="small" /> {errorMessages[m]}
                                    </Typography>
                                </Box>
                            )
                    )}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell align="right" padding="checkbox">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={() => selectRow(row.id)} size="small" />
                </TableCell>
                <TableCell>
                    <TextField
                        sx={{minWidth: '140px'}}
                        value={editState.name !== undefined ? editState.name : row.name}
                        onChange={e => handleChange('name', e.target.value)}
                        onBlur={() => handleSaveChanges('name')}
                    />
                </TableCell>
                {globalValues &&
                    Object.entries(globalValues)?.map(([key, options]) => {
                        return (
                            <TableCell key={key}>
                                <Select
                                    sx={{height: '30px', padding: '1px 2px'}}
                                    fullWidth
                                    value={editState[key] ? editState[key] : row.other_categories[key]}
                                    onChange={e => handleChange(key, e.target.value)}
                                    onBlur={() => handleSaveChanges(key)}>
                                    {(options as string[])?.sort().map((option: string) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        );
                    })}
            </TableRow>
            <TableRow>
                <TableCell sx={{p: '5px'}} colSpan={5 + (!globalValues ? 2 : Object.keys(globalValues)?.length)}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SampleMetadataTable sample={row} experiment={exp} />
                        <SampleFilesTable
                            sample={row}
                            experiment={exp}
                            setRetryUpload={setRetryUpload}
                            setEditMeta={setEditMeta}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            processAllFiles={processAllFiles}
                            uploading={uploading}
                            finished={finished}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
            {/* ---- Retry File Upload Popup ----> */}
            <Popup isOpen={!!retryUpload}>
                <Box p={3}>
                    {!uploading ? (
                        <Box>
                            <Typography variant="title" size="large" p={1} mb={2}>
                                Select the File: {retryUpload?.name}
                            </Typography>
                            <FileUploadBox maxFiles={1} selectedFiles={selectedFiles} onFilesSelected={setSelectedFiles} />
                            {selectedFiles?.[0] && selectedFiles[0]?.name !== retryUpload?.name && (
                                <Typography variant="title" size="medium" color="error" px={2} mt={1}>
                                    {' '}
                                    <ErrorSharp fontSize="small" /> Please choose the file with the name listed above in order to proceed,
                                    or click cancel then edit the filename and click retry upload again.{' '}
                                </Typography>
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                disabled={selectedFiles?.length < 1 || selectedFiles[0]?.name !== retryUpload?.name}
                                sx={{mt: 1, alignSelf: 'center', px: 2, py: 1}}
                                onClick={processAllFiles}>
                                Upload
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{mt: 1, mx: 1, alignSelf: 'center', px: 2, py: 1}}
                                onClick={() => setRetryUpload(null)}>
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            {selectedFiles?.map((f: any) => (
                                <Typography variant="body" size="medium">
                                    {f.name}{' '}
                                    {finished.includes(f.name) ? (
                                        <CheckCircle />
                                    ) : (
                                        <GradientCircularProgress gradientId="gradient-add-files" />
                                    )}{' '}
                                </Typography>
                            ))}
                        </Box>
                    )}
                </Box>
            </Popup>
            <Popup isOpen={editMeta ? true : false}>
                {' '}
                <EditMetaForm file={editMeta} expId={exp?.id} sId={row?.id} refetch={fetchSamples} setEdit={setEditMeta} />{' '}
            </Popup>
        </>
    );
}
